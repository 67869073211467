import React from 'react'
import { useState } from 'react';
import { Modal, Rate, Input, Button, message } from 'antd';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const ReviewModal = ({ visible, onClose, orderId, customerId, itemId }) => {
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [ratingError, setRatingError] = useState('');
    const [reviewTextError, setReviewTextError] = useState('');
  console.log(orderId, 'o id')
  console.log(customerId, 'c id')
  console.log(itemId, 'i id')
    const handleRatingChange = (value) => {
      setRating(value);
      setRatingError(''); // Clear rating error on change
    };
  
    const handleReviewTextChange = (e) => {
      setReviewText(e.target.value);
      setReviewTextError(''); // Clear review text error on change
    };
  
    const handleSubmit = async () => {
      let isValid = true;
  
      if (rating === 0) {
        setRatingError('Please provide a rating');
        isValid = false;
      }
  
      if (reviewText.trim() === '') {
        setReviewTextError('Please enter a review');
        isValid = false;
      }
  
      if (isValid) {
        // Perform your submission logic here
        const payload = {
          customer_id: customerId,
          description: reviewText,
          rating: rating,
          order_id: orderId,
          item_id: itemId
        };
  
        try {
          const res = await axios.post('https://beta.desibanter.co.uk/api/review', payload)
          if (res.data.success) {
            message.success('Review submitted successfully');
          }
        } catch (error) {
        }
  
        onClose(); // Close the modal after submission
      }
    };
  
    const handleCancel = () => {
      // Clear state values on cancel
      setRating(0);
      setReviewText('');
      setRatingError('');
      setReviewTextError('');
      onClose();
    };
  
    return (
      <Modal
        title="Write a Review"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <p>Rating:</p>
          <Rate allowHalf onChange={handleRatingChange} value={rating} />
          <p style={{ color: 'red' }}>{ratingError}</p>
        </div>
        <div style={{ marginTop: '16px' }}>
          <p>Review:</p>
          <Input.TextArea rows={4} onChange={handleReviewTextChange} value={reviewText} />
          <p style={{ color: 'red' }}>{reviewTextError}</p>
        </div>
      </Modal>
    );
};

