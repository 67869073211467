import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result, Spin } from "antd";
import { useSpring, animated } from "react-spring";
import Theme from "../utils/Theme";
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/counter/CartSlice";

const AnimatedResult = animated(Result);

export const Success = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resultAnimation = useSpring({
    opacity: isLoading ? 0 : 1,
    transform: isLoading ? "scale(0)" : "scale(1)",
    config: { tension: 200, friction: 10 },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          session_id: sessionId,
        };
        const response = await axios.post(
          "https://beta.desibanter.co.uk/api/verify-payment",
          payload
        );

        if (response?.data && response?.data?.success === true) {
          setIsSuccess(true);
          dispatch(clearCart());
          setTimeout(() => {
            navigate("/orderHistory");
          }, 9000);
        }
      } catch (error) {
        setTimeout(() => {
          navigate("/");
        }, 9000);
        console.error("Error while verifying payment:", error);
      } finally {
        // Update loading state regardless of success or failure
        setIsLoading(false);
      }
    };

    if (sessionId) {
      fetchData();
    }
  }, [sessionId]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {isLoading ? (
        
          <Spin tip={'Loading...'} size="large" />
      
      ) : isSuccess ? 
        <animated.div style={resultAnimation}>
          <Result
            status="success"
            title="Payment has been made successfully"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            extra={[
              <Button
                type="danger"
                key="console"
                onClick={() => navigate("/orderHistory")}
                style={{
                  fontFamily: Theme.customFont.custFontNormal,
                  backgroundColor: "red",
                  color: '#fff'
                }}
              >
                Go To Orders
              </Button>,
            ]}
          />
        </animated.div>
       : null}
    </div>
  );
};
