import React, { useState, useEffect } from "react";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "../App.css";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
  MDBRow,
  MDBTooltip,
  MDBTypography,
} from "mdb-react-ui-kit";
import { json, useLocation, useNavigate, useNavigation } from "react-router";
import { Empty, Form, Image, Input, InputNumber, message, Rate, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { increment, decrement } from "../redux/counter/CounterSlice";
import {
  addToCart,
  removeFromCart,
  deleteFromCart,
  clearCart,
} from "../redux/counter/CartSlice";
import UserProfileModal from "../components/UserProfileModal";
import Theme from "../utils/Theme";
import axios from "axios";
export const AddtoCart = () => {
  const { cartData, totalAmount, totalQuantity } = useSelector(
    (state) => state.cartItems
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const { profileData } = useSelector((state) => state.userProfile);
  const [isDisable, setisDisable] = useState(userData !== null ? true : false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemQuantities, setItemQuantities] = useState({});
  const [isDisableInc, setisDisableInc] = useState(false);
  const [form] = Form.useForm();


  let shippingCharges = 0;

  const hasShippingCharge =
    cartData &&
    cartData.some((e) => e.delivery_option && e.delivery_option.price);

  if (hasShippingCharge) {
    // Find the first item with a delivery_option.price and set shippingCharge to that value
    const itemWithShippingCharge = cartData.find(
      (e) => e.delivery_option && e.delivery_option.price
    );
    shippingCharges = itemWithShippingCharge.delivery_option.price;
  }

  useEffect(() => {
    // Initialize itemQuantities with quantities from cartData
    const initialQuantities = {};
    cartData.forEach((item) => {
      initialQuantities[item._id] = item.number_item;
    });
    setItemQuantities(initialQuantities);
  }, [cartData]);

  useEffect(() => {
    //dispatch(clearCart())
    // Update localData when reduxData changes
    //setLocalData(cartData);
    if (cartData && cartData.length < 0) {
      setisDisable(true);
    }
  }, []);

  useEffect(() => {
    if (profileData?.data) {
      form.setFieldsValue(profileData?.data);
    } else {
      form.setFieldsValue(userData?.data);
    }
  }, [profileData, form, userData]);

  const handleSubImageClick = (itemId, subImage) => {
    // Update the selectedImage state for the specific item
    setSelectedImage((prevImages) => ({
      ...prevImages,
      [itemId]: subImage,
    }));
  };

  function removeProduct(id) {
    //dispatch(decrement());
    dispatch(removeFromCart(id));
  }

  function deleteItem(id) {
    //dispatch(decrement());
    dispatch(deleteFromCart(id));
  }

  function addProduct(ele) {
    const { _id, price } = ele;
    const updatedProduct = { _id, price, number_item: 1 };
    //dispatch(increment());
    if (itemQuantities[_id] < ele.quantity) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [_id]: prevQuantities[_id] + 1,
      }));
      dispatch(addToCart(updatedProduct));
    } else {
      
      
      
      // Handle the case where the quantity is already at the maximum
      
    }
  }

  const requiredFields = [
    "name",
    "email",
    "mobile_number",
    "country",
    "city",
    "postalcode",
  ];

  const handleSave = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const formData = new FormData();
      const extractedData = cartData.map((item) => ({
        item_id: item._id,
        category_id: item.category_id,
        title: item.title,
        quantity: item.number_item,
        price: item.price,
        description: item?.message,
        thumbnail: item?.thumbnail,
        coding: item?.coding,
      }));

      const payload = {
        customer_id: userData?.data?._id,
        mobile_number: values.mobile_number,
        billing_address: values.address,
        totalprice: totalAmount + shippingCharges,
        customer_name: values.name,
        email: values.email,
        country: values.country,
        postal_code: values.postal_code,
        city: values.city,
        town: values.town,
        description: values?.comment ? values.comment : "",
        card_items: extractedData,
      };

      const response = await axios.post(
        "https://beta.desibanter.co.uk/api/order",
        payload
      );
      if (response?.data && response?.data?.success === true) {
        const url = response?.data?.data?.session?.url;
        fetch(url)
          .then((response) => response.json()) // or response.text(), depending on the expected response format
          .then((data) => {
            // Handle the response data
          })
          .catch((error) => {
            console.error("Error fetching data from the URL:", error);
          });
        dispatch(clearCart());
        // Navigate after making the fetch request if needed
        window.location.href = url;

        navigate("/success");
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  function checkLogin() {
    if (!userData) {
      navigate("/login");
    } else {
      if (!profileData && !profileData?.data?.address) {
        navigate("/profile");
      } else {
        handleSave();
      }
    }
  }
  return (
    <div className="h-100 ">
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center my-4">
          <MDBCol md="8">
            <MDBCard className="mb-4">
              <MDBCardHeader className="py-3">
                <MDBTypography
                  tag="h5"
                  className="mb-0"
                  style={{ fontFamily: Theme.customFont.custFontNormal }}
                >
                  {`Cart - ${totalQuantity}  items`}
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                {cartData && cartData?.length > 0 ? (
                  cartData?.map((ele, index) => {
                    return (
                      <div key={ele._id}>
                        <MDBRow>
                          <MDBCol lg="3" md="12" className="mb-4 mb-lg-0">
                            <MDBRipple
                              rippleTag="div"
                              rippleColor="light"
                              className="bg-image rounded hover-zoom hover-overlay"
                            >
                             <MDBCardImage
  alt="product"
  src={ ele.thumbnail}
  style={{
    height: 120,
    width: "100%",
    objectFit: "cover",
  }}
/>
                            </MDBRipple>
                            {/* <div className="sub-images-container">
                            {ele?.images?.map((subImage, subIndex) => (
  <div
    key={subIndex}
    onClick={() => handleSubImageClick(ele._id, subImage)}
    className={`sub-image-container ${
      subIndex !== 0 ? "with-margin" : ""
    }`}
  >
    
    <img
      alt={`sub-image-${subIndex}`}
      src={subImage}
      className="sub-image"
    />
  </div>
))}
                            </div> */}
                          </MDBCol>

                          <MDBCol lg="5" md="6" className=" mb-4 mb-lg-0">
                            <p
                              style={{
                                fontFamily: Theme.customFont.custFontNormal,
                              }}
                            >
                              <strong>{ele.title}</strong>
                            </p>
                            <Typography.Paragraph
                              ellipsis={{
                                rows: 3,
                                expandable: true,
                                symbol: "more",
                              }}
                              style={{
                                fontFamily: Theme.customFont.custFontNormal,
                              }}
                            >
                              {ele.description}
                            </Typography.Paragraph>
                          </MDBCol>
                          <MDBCol
                            lg="4"
                            md="6"
                            className="mb-4 mb-lg-0"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              className="d-flex mb-4"
                              style={{ maxWidth: "210px" }}
                            >
                              <MDBBtn
                                onClick={() =>
                                  ele.number_item > 1
                                    ? removeProduct(ele._id)
                                    : null
                                }
                                className="px-3 me-2"
                                style={{
                                  backgroundColor: "rgb(243, 70, 70)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <MDBIcon fas icon="minus" size="1x" />
                              </MDBBtn>

                              <MDBInput
                                value={ele.number_item}
                                //type="number"
                                //label="Quantity"
                                labelStyle={{
                                  fontFamily: Theme.customFont.custFontNormal,
                                }}
                                className="text-center"
                                style={{
                                  WebkitAppearance: "none",
                                  MozAppearance: "textfield",
                                  fontFamily: Theme.customFont.custFontNormal,
                                }}
                              />

                              <MDBBtn
                                onClick={() => addProduct(ele)}
                                className="px-3 ms-2"
                                disabled={itemQuantities[ele._id]  >= ele.quantity  ?
                                  true 
                                   : false 
                                }
                                style={{
                                  backgroundColor: "rgb(243, 70, 70)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <MDBIcon fas icon="plus" size="1x" />
                              </MDBBtn>
                              <div style={{ marginLeft: 20, display: "flex" }}>
                                <MDBTooltip
                                  wrapperProps={{ size: "sm" }}
                                  wrapperClass=" custom-tooltip"
                                  title="Remove item"
                                >
                                  <MDBIcon
                                    fas
                                    icon="trash"
                                    onClick={() => deleteItem(ele._id)}
                                  />
                                </MDBTooltip>
                              </div>
                            </div>

                            <p
                              className="text-start"
                              style={{
                                fontFamily: Theme.customFont.custFontNormal,
                              }}
                            >
                              <strong>Price: £{ele.price.toFixed(2)}</strong>
                            </p>
                          </MDBCol>
                        </MDBRow>
                        {index < cartData.length - 1 && <hr className="my-4" />}
                      </div>
                    );
                  })
                ) : (
                  <Empty />
                )}

                <hr className="my-4" />
              </MDBCardBody>
            </MDBCard>

            {cartData.length > 0 ? (
              <MDBCard className="mb-4">
                <MDBCardBody
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <p style={{fontFamily: Theme.customFont.custFontNormal}}>
                  <strong>Expected shipping delivery</strong>
                </p>
                <p className="mb-0">1 – 3 working days</p> */}

                  {cartData &&
                  cartData.length > 0 &&
                  userData &&
                  userData !== null ? (
                    <>
                      {" "}
                      <p
                        style={{
                          fontFamily: Theme.customFont.custFontNormal,
                          fontSize: 25,
                        }}
                      >
                        <strong>Shipping Details</strong>
                      </p>
                      <Form
                        form={form}
                        labelCol={{ span: 8, flex: "0 0 20%" }}
                        wrapperCol={{ span: 16 }}
                        style={{ width: "100%" }}
                        onValuesChange={(changedValues, allValues) => {
                          // Check if any required field is empty
                          const hasEmptyRequiredField = requiredFields.some(
                            (fieldName) =>
                              allValues[fieldName] === undefined ||
                              allValues[fieldName] === ""
                          );

                          // Set isDisable to true if any required field is empty, otherwise set to false
                          setisDisable(hasEmptyRequiredField);
                        }}
                      >
                         <Form.Item
                          label="Name"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Email"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email address",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                          ]}
                        >
                          <Input disabled={true} />
                        </Form.Item>

                       

                        <Form.Item
                          label="Phone No"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="mobile_number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your phone number",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Address"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="City"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your city",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Town"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="town"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your town",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Postal Code"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="postalcode"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your postal code",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>



                        <Form.Item
                          label="Country"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your country",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                      
                        <Form.Item
                          label="Comment"
                          name="comment"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                        >
                          <Input.TextArea placeholder="Optional" />
                        </Form.Item>

                        <Form.Item
                          label="Total"
                          name="total"
                          style={{
                            fontFamily: Theme.customFont.custFontNormal,
                          }}
                        >
                          <strong
                            style={{
                              fontFamily: Theme.customFont.custFontNormal,
                            }}
                          >
                            £{(totalAmount + shippingCharges).toFixed(2)}
                          </strong>
                        </Form.Item>
                      </Form>
                    </>
                  ) : null}
                  <MDBBtn
                    onClick={() => {
                      checkLogin();
                      setisDisable(!isDisable);
                    }}
                    block
                    size="lg"
                    disabled={isDisable}
                    style={{
                      backgroundColor: "rgb(243, 70, 70)",
                      fontWeight: "bold",
                      fontFamily: Theme.customFont.custFontNormal,
                      width: "50%",
                      maxWidth: "400px", // Optional: Set a maximum width for the button
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                    className="mt-3 mt-lg-0"
                  >
                    Pay Now
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            ) : null}
          </MDBCol>
          <MDBCol md="4">
            <MDBCard className="mb-4">
              <MDBCardHeader>
                <MDBTypography
                  tag="h5"
                  className="mb-0"
                  style={{ fontFamily: Theme.customFont.custFontNormal }}
                >
                  Summary
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBListGroup flush>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 pb-0"
                    style={{ fontFamily: Theme.customFont.custFontNormal }}
                  >
                    Products
                    <span>£{totalAmount.toFixed(2)}</span>
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0"
                    style={{ fontFamily: Theme.customFont.custFontNormal }}
                  >
                    Shipping
                    <span>£{shippingCharges}</span>
                  </MDBListGroupItem>
                  <MDBListGroupItem
                    className="d-flex justify-content-between align-items-center border-0 px-0 mb-3"
                    style={{ fontFamily: Theme.customFont.custFontNormal }}
                  >
                    <div>
                      <strong>Total amount</strong>
                    </div>
                    <span
                      style={{ fontFamily: Theme.customFont.custFontNormal }}
                    >
                      <strong>
                        £{(totalAmount + shippingCharges).toFixed(2)}
                      </strong>
                    </span>
                  </MDBListGroupItem>
                </MDBListGroup>

                {/* <MDBBtn
                  onClick={() => checkLogin()}
                  block
                  size="lg"
                  style={{
                    backgroundColor: "rgb(243, 70, 70)",
                    fontWeight: "bold",
                    fontFamily: Theme.customFont.custFontNormal
                  }}
                >
                  Go to checkout
                </MDBBtn> */}
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody>
                    <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                      <strong>We accept</strong>
                    </p>
                    <MDBCardImage
                      className="me-2"
                      width="45px"
                      src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/visa.svg"
                      alt="Visa"
                    />
                    <MDBCardImage
                      className="me-2"
                      width="45px"
                      height="28px"
                      src="https://media.designrush.com/inspiration_images/135143/conversions/_1510164528_150_social-mobile.jpg"
                      alt="Stripe"
                    />
                    <MDBCardImage
                      className="me-2"
                      width="45px"
                      src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg"
                      alt="Mastercard"
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBCardBody>
            </MDBCard>
            <MDBBtn
              style={{
                fontWeight: "bold",
                fontFamily: Theme.customFont.custFontNormal,
                marginTop: 10,
                alignSelf: "center",
                display: "flex",
                margin: "auto",
                alignItems: "center",
              }}
              color="dark"
              onClick={() => navigate("/")}
            >
              <MDBIcon
                icon="arrow-right"
                size="1x"
                className="me-2"
                style={{ color: "#fff" }}
              />
              Countinue Shopping
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
