import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { clearLoginError, login } from "../redux/counter/AuthSlice";
import { clearProfile, setProfileData } from "../redux/counter/ProfileSlice";
import { useNavigate } from "react-router";
import Theme from "../utils/Theme";
import {message} from 'antd'
import axios from "axios";
import { clearCart } from "../redux/counter/CartSlice";
import { clearFav } from "../redux/counter/FavSclice";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData, isError, isSucess, loginError } = useSelector(
    (state) => state.auth
  );

  const {profileData} = useSelector(state=> state.userProfile)

  const getFieldsValue = async()=>{
    try {
      const res = await axios.get(
        `https://beta.desibanter.co.uk/api/profile?customer_id=${userData?.data?._id}`
      );
      dispatch(setProfileData(res.data));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    // Reset the alertClosed state when isError becomes true
    if (userData !== null) {
      // if(userData?.data?._id !== profileData?.data?.customer_id)
      // {
      //   dispatch(clearCart())
      //   dispatch(clearFav())
      // }
      message.success("login sucesss");
      getFieldsValue()
      navigate("/");
    }
  }, [userData]);

  useEffect(() => {
    // Check if signupError has changed and is not null, then set the error state
    if (loginError && loginError) {
      //setError(signupError);
      message.error(`${loginError}`, 3);

      // Dispatch the action to clear the signupError
      dispatch(clearLoginError());
    }
    if (userData) {
      message.success("login sucesss");
    }
  }, [loginError, dispatch,]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if fields are empty
    if (!email.trim() || !password.trim()) {
      setError("Please fill in all fields.");
      return;
    }

    const params = {
      email: email,
      password: password,
    };

    dispatch(login(params));

    // Reset the form and state
    setEmail("");
    setPassword("");
    setError("");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <MDBContainer className="my-5 gradient-form">
      <MDBRow>
        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column ms-5 align-items-center">
            <div className="text-center">
              <img
                src={require("../assets/images/logo.jpeg")}
                style={{ width: "185px" }}
                alt="logo"
              />
              <h4
                className="mt-1 mb-5 pb-1"
                style={{ fontFamily: Theme.customFont.custFontNormal }}
              >
                We are The DesiBanter Team
              </h4>
            </div>

            <p
              style={{
                alignSelf: "center",
                fontFamily: Theme.customFont.custFontNormal,
              }}
            >
              Please login to your account
            </p>

            <form onSubmit={handleSubmit} style={{ alignSelf: "center" }}>
              <MDBInput
                value={email}
                onChange={handleEmailChange}
                wrapperClass="mb-4"
                label="Email address"
                labelStyle={{ fontFamily: Theme.customFont.custFontNormal }}
                id="form1"
                type="email"
              />
              <MDBInput
                value={password}
                onChange={handlePasswordChange}
                wrapperClass="mb-4"
                label="Password"
                labelStyle={{ fontFamily: Theme.customFont.custFontNormal }}
                id="form2"
                type="password"
              />
              {error && <p style={{ color: "red" }}>{error}</p>}

              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn
                  type="submit"
                  className="mb-4 w-100 gradient-custom-2"
                  style={{ fontFamily: Theme.customFont.custFontNormal }}
                >
                  Login
                </MDBBtn>
                <a
                  className="text-muted"
                  href="#!"
                  style={{ fontFamily: Theme.customFont.custFontNormal }}
                >
                  Forgot password?
                </a>
              </div>
            </form>
            <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
              <p
                className="mb-0"
                style={{ fontFamily: Theme.customFont.custFontNormal }}
              >
                Don't have an account?
              </p>
              <MDBBtn
                onClick={() => handleSignup()}
                outline
                className="mx-2"
                color="danger"
                style={{ fontFamily: Theme.customFont.custFontNormal }}
              >
                Create New
              </MDBBtn>
            </div>
          </div>
        </MDBCol>

        <MDBCol col="6" className="mb-5">
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4
                class="mb-4"
                style={{ fontFamily: Theme.customFont.custFontNormal }}
              >
                CREATING BESPOKE DESI GREETING CARDS JUST FOR YOU!
              </h4>
              <p
                class="small mb-0"
                style={{ fontFamily: Theme.customFont.custFontNormal }}
              >
                We create unique greeting cards using the latest technology,
                with innovative designs and with a slight 'Desi Banter' to keep
                you smiling. All our cards are of the highest of qualities,
                printed on A4 300gsm white cards with a hammer effect to give
                you that extra wow factor.
              </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
