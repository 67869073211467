//colors

const color = {
    
    red: '#db2828',
   
  };
  
  //other
  
  const customFont = {
    custFontNormal: 'Cursive'
    
  }

  
  export default {color, customFont }