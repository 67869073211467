import React, {useEffect, useState} from 'react';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBTypography, MDBBtn } from 'mdb-react-ui-kit';
import { Typography } from 'antd';
import Theme from '../utils/Theme';
import { ReviewModal } from '../components/ReviewModal';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { API } from '../api';

const OrderDetails = () => {
  // Dummy data for shipping details
  const location = useLocation();
    const data = location.state.data;
    const { userData } = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
 console.log(data, 'data')
  const showModal = (id) => {
    setSelectedItemId(id);
    setIsModalVisible(true);
  };

  useEffect(() => {
   getHistory()
  }, [])
  
  async function getHistory() {
    try {
      const res = await API.get(`order/${data?._id}`);
      console.log(res.data)
    } catch (error) {
      // Handle error
    }
  }
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItemId(null);
  };
  const shippingDetails = {
    name: data?.customer?.name,
    email: data?.customer?.email,
    phone_number: data?.customer?.mobile_number,
    address: data?.billing_address,
    total_price: data?.totalprice,
    status: data?.status
    // city: data?.city,
    // town: 'Townsville',
    // postalcode: '12345',
    // country: 'Countryland',
  };

  // Dummy data for cart item
  const cartItem = data?.card_item

  // Function to handle adding a review
  const handleAddReview = () => {
    // Add your logic here
    console.log('Adding a review');
  };

  return (
    
      <MDBContainer className="py-2 h-100">
        <Typography.Title style={{alignSelf: 'center'}} level={2}>{`Order Id: ${data?.order_count_key} `}</Typography.Title>
        <MDBRow className=" my-4">
          <MDBCol md="8">
          <MDBCard className="mb-4">
              <MDBCardBody>
                {/* Dummy shipping details */}
                <div style={{ marginBottom: 20 }}>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Name:</strong> {shippingDetails.name}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Email:</strong> {shippingDetails.email}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Phone No:</strong> {shippingDetails.phone_number}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Address:</strong> {shippingDetails.address}
                  </p>
                </div>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Total:</strong> £{shippingDetails.total_price.toFixed(2)}
                </p>

                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Status:</strong> {shippingDetails.status}
                </p>
               
              </MDBCardBody>
            </MDBCard>
            

            {data?.card_items?.map((cartItem, index) => (
        <MDBCard className="mb-4" key={index}>
          <MDBCardBody>
            <MDBRow>
              <MDBCol lg="3" md="12" className="mb-4 mb-lg-0">
                <img
                  src={`https://admin.desibanter.co.uk/${cartItem?.thumbnail}`}
                  alt="Product Thumbnail"
                  style={{ height: 120, width: '100%', objectFit: 'cover' }}
                />
              </MDBCol>
              <MDBCol lg="5" md="6" className="mb-4 mb-lg-0">
                <p style={{ fontFamily: 'Theme.customFont.custFontNormal' }}>
                  <strong>{cartItem?.title}</strong>
                </p>
              </MDBCol>
              <MDBCol lg="4" md="6" className="mb-4 mb-lg-0" style={{ display: "flex", flexDirection: "column" }}>
                <MDBBtn
                  onClick={() => showModal(cartItem?.item_id)} // Assuming cartItem has an 'id' property
                  className="px-3 ms-2"
                  style={{
                    backgroundColor: 'rgb(243, 70, 70)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    fontSize: 15
                  }}
                >
                  Write Review
                </MDBBtn>
                <p
                  className="text-center mt-3"
                  style={{
                    fontFamily: 'Theme.customFont.custFontNormal',
                  }}
                >
                  <strong>Price: £{cartItem.price.toFixed(2)}</strong>
                </p>
              </MDBCol>
            </MDBRow>
            <hr className="my-4" />
          </MDBCardBody>
        </MDBCard>
      ))}

            
          </MDBCol>
        </MDBRow>
        <ReviewModal visible={isModalVisible} onClose={handleCancel} orderId={data?._id} customerId={userData?.data?._id} itemId={selectedItemId} />
      </MDBContainer>
    
  );
};

export default OrderDetails;