import { Col, Layout, Row, Typography, Pagination, Rate, Empty } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cards } from "../components/Cards";
import { DropDowns } from "../components/DropDowns";
import { SelectDropDown } from "../components/SelectDropDown";
import "../App.css";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import images from "../image.json";
import { getAllProducts } from "../redux/counter/ProductSlice";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import Theme from "../utils/Theme";
import { API } from "../api";
import axios from "axios";

export const Home = () => {
  const { Content } = Layout;
  const [sortedItems, setSortedItems] = useState("highlow");

  const dispatch = useDispatch();
  const { productsData, isLoading } = useSelector((state) => state.products);
  const [isHovered, setIsHovered] = useState(false);
  const [isItemsHovered, setIsItemsHovered] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [categoryData, setcategoryData] = useState(null)
  const [categoryList, setcategoryList] = useState(null)
  const [commonData, setcommonData] = useState(null)
  const [reviewsData, setreviewsData] = useState(null)

 const getReviews = async ()=>{
  const response = await axios.get('https://beta.desibanter.co.uk/api/reviews/list')
  console.log(response.data.data)
  setreviewsData(response?.data?.data)

 }

  const fetchCategoryList = async () => {
    try {
      const response = await API.get('categories/list');
      setcategoryList(response.data);
    } catch (error) {
      console.error(error, 'error');
      // Instead of returning the entire error object, you can handle the error here
    }
  };
  useEffect( () => {
   
    fetchCategoryList()
    

  }, []);

  useEffect(() => {
    getReviews()
  }, [])
  

  const uniqueCategories = ['All',...new Set(categoryList?.data?.map((option) => option.name
    ))];

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  const fetchCategoryData = async ()=>{
    if(selectedCategory && selectedCategory !== 'All'){
      const foundItem = categoryList?.data.find((e)=> e.name === selectedCategory)
      try {
        let baseUrl = `items/list?category_id=${foundItem._id}`;

        // Check if sortItems is 'highlow', then add sort parameter
        if (sortedItems === 'highlow') {
          baseUrl += '&sort=desc';
        }else{
          baseUrl += '&sort=asc';
        }
        const response = await API.get(baseUrl);
        setcategoryData(response.data)
      } catch (error) {
        console.log(error, 'error')
        // Instead of returning the entire error object, throw a new Error
        throw new Error(error.message);
      }
    }  

    else {
      try {
        let baseUrl = 'items/list';

    // Check if sortItems is 'highlow', then add sort parameter
    if (sortedItems === 'highlow') {
      baseUrl += '?sort=desc';
    }else if ( sortedItems === 'lowhigh') {
      baseUrl += '?sort=asc';
    }else{
      baseUrl = baseUrl
    }
        const response = await API.get(baseUrl);
        setcommonData(response.data)
      } catch (error) {
        console.log(error, 'error')
        // Instead of returning the entire error object, throw a new Error
        throw new Error(error.message);
      }
    }
  }

   useEffect(()  => {
    fetchCategoryData()
    
    
  }, [selectedCategory, sortedItems,])
  

  
  // const filterData = useMemo(() => {
  //   let filteredData = categoryData && categoryData ? [...categoryData.data] : productsData && [...productsData?.data];
    
  //   if (selectedSearch) {
  //     const lowerCaseSearch = selectedSearch.toLowerCase();
  //     filteredData = filteredData.filter((product) =>
  //       product.title.toLowerCase().includes(lowerCaseSearch)
  //     );
      
  //   }
    
  
  //   return filteredData;
  // }, [productsData?.data,categoryData, selectedSearch]);

  const filterData = useMemo(() => {
    let filteredData = [];
  
    if (categoryData?.data && selectedCategory && selectedCategory !== 'All') {
      filteredData = [...categoryData?.data];
     
      
    }
  
    if (commonData && !selectedCategory || commonData && selectedCategory === 'All') {
      filteredData = [ ...commonData?.data];
  
    }

    if (selectedSearch && selectedCategory === 'All' || selectedSearch && selectedCategory !== 'All') {
      const lowerCaseSearch = selectedSearch.toLowerCase();
      filteredData = filteredData.filter((product) =>
        product.title.toLowerCase().includes(lowerCaseSearch)
      );
    }
  
    
  
    return filteredData;
  }, [categoryData?.data, productsData?.data, selectedSearch, commonData?.data]);



  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const testimonialsData = [
    {
      id: 1,
      name: "Teresa May",
      avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
      rating: 4.5,
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    },
    {
      id: 2,
      name: "John Doe",
      avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
      rating: 3.5,
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    },
    {
      id: 3,
      name: "Jane Smith",
      avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
      rating: 5,
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    },
    {
      id: 4,
      name: "Jane Smith",
      avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
      rating: 5,
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    },
    {
      id: 5,
      name: "Jane Smith",
      avatar: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp",
      rating: 5,
      review:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic tenetur.",
    },
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTestimonials = reviewsData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const handleReviewsClick = () => {
    // Scroll to the reviews section
    const reviewsSection = document.getElementById("reviews");
    if (reviewsSection) {
      reviewsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Map through the products array and update image paths

  return (
    <>
      <div style={{ marginTop: 20 }}>
        <Header />
        <Layout
          style={{
            maxHeight: "60vh",
            background: "transparent",
            maxWidth: "100vw",
            marginTop: 20,
          }}
        >
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                width: "80%",
                maxWidth: "600px",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  fontFamily: Theme.customFont.custFontNormal,
                }}
              >
                CREATING BESPOKE DESI GREETING CARDS JUST FOR YOU!
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  fontFamily: Theme.customFont.custFontNormal,
                }}
              >
                We create unique greeting cards using the latest technology,
                with innovative designs and with a slight 'Desi Banter' to keep
                you smiling. All our cards are of the highest of qualities,
                printed on A5 300gsm white cards with a hammer effect to give
                you that extra wow factor.
              </Typography.Paragraph>
            </div>
          </Content>
        </Layout>
        <DropDowns
          data={categoryData && selectedCategory !== 'All' ? categoryData :  commonData}
          uniqueCategories={uniqueCategories}
          onCategorySelect={(value) => setSelectedCategory(value)}
          onSearchSelect={(value) => setSelectedSearch(value)}
        />
        <Row
          gutter={16}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Col
            xs={11}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ display: "flex", alignItems: "center", paddingLeft: 0, paddingRight: 0 }}
            onMouseLeave={() => setIsItemsHovered(true)}
          >
            <Typography.Text
              style={{
                marginLeft: ".5rem",
                fontSize: 22,
                fontWeight: 600,
                position: "relative",
                cursor: "pointer",
                fontFamily: Theme.customFont.custFontNormal,
              }}
              onMouseEnter={() => setIsItemsHovered(true)}
              // onMouseLeave={() => setIsItemsHovered(false)}
            >
              Items
              <span
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: isItemsHovered ? "100%" : "0%",
                  height: "4px",
                  backgroundColor: "red",
                  transition: "width 0.3s ease-in-out",
                }}
              ></span>
            </Typography.Text>

            <Typography.Text
              style={{
                marginLeft: ".7rem",
                fontSize: 22,
                fontWeight: 600,
                position: "relative",
                cursor: "pointer",
                fontFamily: Theme.customFont.custFontNormal,
              }}
              onMouseEnter={() => {
                setIsItemsHovered(false);
                setIsHovered(true);
              }}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => handleReviewsClick()}
            >
              Reviews
              <span
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: isHovered ? "100%" : "0%",
                  height: "4px",
                  backgroundColor: "red",
                  transition: "width 0.3s ease-in-out",
                }}
              ></span>
            </Typography.Text>
          </Col>
          <Col
            xs={12}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <SelectDropDown setSortedItems={setSortedItems} />
          </Col>
        </Row>

        <Cards productsData={filterData} selectedCategory={selectedCategory} selectedSearch={selectedSearch} />

        {/* Content for the Reviews section goes here */}

        {/* <Footer /> */}
      </div>
      <div id="reviews">
         <MDBContainer
          fluid
          className="py-5"
          style={{ backgroundColor: "rgba(254,182,182, 0.2)", color: "#000" }}
        >
          <Typography.Title
            level={3}
            style={{ paddingBottom: 20, paddingLeft: 20, fontFamily: Theme.customFont.custFontNormal }}
          >
            Reviews {`( ${reviewsData?.length} )`}
          </Typography.Title>
          <MDBRow className="text-center" style={{ display: "flex", flexDirection: 'column', alignItems:'center', justifyContent:'center' }}>
  {currentTestimonials && currentTestimonials.length > 0 ? currentTestimonials.map((testimonial, index) => (
    <MDBCol key={index} md="4" lg= '12' className="mb-4 mb-md-0 ml-8" style={{alignSelf: 'center', display: 'contents'}}>
      <MDBCard className="hover-shadow" style={{marginBottom: 30, width: '90%', alignSelf: 'center'}}>
        <MDBCardBody className="py-4 mt-2 d-flex flex-column align-items-start">
          <div className="d-flex align-items-center flex-start mb-3"> {/* New div for image and name */}
            <img
              src={'https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png'}
              className="rounded-circle shadow-1-strong"
              width="40"
              height="40"
              style={{ background: 'red' }}
            />
            <h5
              className="font-weight-bold mt-3 ms-3" // Add left margin for name
              style={{ fontFamily: Theme.customFont.custFontNormal }}
            >
              {testimonial.customer_name}
            </h5>
          </div>
          <div className="d-flex flex-column align-items-start" style={{paddingRight: '4rem',
    paddingLeft: '3rem'}}>
          <MDBTypography
            listUnStyled
            className="d-flex justify-content-center mb-2" // Move the rating to center
            
          >
            <Rate value={testimonial.rating} />
          </MDBTypography>
          <p
            className="mb-2 text-center" // Align quote text to center
            style={{ fontFamily: Theme.customFont.custFontNormal }}
          >
            <MDBIcon fas icon="quote-left" className="pe-2" />
            {testimonial.description}
          </p>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  )) : <Empty />}
</MDBRow>

        {currentTestimonials && currentTestimonials.length > 0 ? <div className="d-flex justify-content-center mt-4">
            <Pagination
              current={currentPage}
              total={reviewsData?.length}
              pageSize={itemsPerPage}
              onChange={handleChangePage}
              showSizeChanger={false}
            />
          </div> : null}  
        </MDBContainer>
        
      </div>
    </>
  );
};
